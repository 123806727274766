<template>
  <section class="dtc-main-section mt-2 mr-2">
    <EquipmentPurchases></EquipmentPurchases>
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-暑期課程學分費補助申請清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select :options="schoolObject" v-model="searchSchool" @change="searchDb" :disabled="healthCodeCheck">
          </b-select>
        </b-input-group>
        <b-input-group prepend="年度">
          <b-select :options="years" v-model="searchYear" @change="searchDb"></b-select>
        </b-input-group>
        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div v-for="(item, i) in headers" :key="`headers${i}`">
        {{ item }}
      </div>
    </header>
    <main class="dtc-grid-header content" v-for="(item, i) in items" :key="i" style="color:#39312E;" :style="
      i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
    ">
      <div style="cursor: pointer;padding-top:3px;">
        {{ i + 1 }}
      </div>
      <div style="padding:2px 0">
        <b-button variant="warning" size="sm" @click="apply(item)" :disabled='!isEditable(item)'>提出</b-button>
      </div>
      <div style="padding:2px 0">
        <b-button variant="primary" size="sm" @click="openSubsidyEdit(item)">編輯</b-button>
      </div>

      <div :id="`itemOne${i}`" :title="item.StatusName"> {{ item.StatusName }} </div>

      <!-- <b-tooltip
        :target="`itemOne${i}`"
        placement="top"
        :variant="item.StatusName == '完成核定' ? 'primary' : 'danger'"
        style="text-align:left;min-width:300px"
        v-if="item.StatusName == '完成核定' || item.StatusName == '已退回'"
      >
        <div v-if="item.StatusName == '完成核定'" style="text-align: left;">
          完成核定<br />請於{{
            item.PerAuditComment ? $twDate4(item.PerAuditComment) : ""
          }}前函文
        </div>
        <div v-else style="text-align: left;">
          退回申請<br />原因:{{ item.RejectComment || "" }}
        </div>
      </b-tooltip>
      <div
        v-if="item.StatusName == '完成核定'"
        style="padding:0;text-align:center"
      >
        <b-input
          v-model.trim="item.ApplyNumber"
          placeholder="公文號"
          @change="saveData"
          style="text-align: center"
        ></b-input>
      </div>
      <div v-else></div> -->

      <div>
        {{ item.ApplyDate ? $twDate(item.ApplyDate) : "" }}
      </div>

      <div>{{ item.PassDate ? $twDate(item.PassDate) : "" }}</div>
      <div :title="item.SchoolName">{{ item.SchoolName }}</div>
      <div :title="item.BYear">{{ item.BYear }}</div>
      <div :title="item.Apply_Amt">{{ item.Apply_Amt ? $formatPrice(item.Apply_Amt) : "" }}</div>

      <!-- <div v-if="item.StatusName == '完成核定'">
        <div>
          {{ item.ApproveDate ? $twDate(item.ApproveDate) : "暫無資料" }}
        </div>
      </div>
      <div v-else></div>

      <div v-if="item.StatusName == '完成核定'">
        <div>
          {{ item.Approve_Amt ? $formatPrice(item.Approve_Amt) : "暫無資料" }}
        </div>
      </div>
      <div v-else></div>

      <div v-if="item.StatusName == '完成核定'">
        <div>
          {{ item.ApproveNumber || "暫無資料" }}
        </div>
      </div>
      <div v-else></div> -->
    </main>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import EquipmentPurchases from "./EquipmentPurchases";

const headers = [
  "序",
  "提出申請",
  "暑期學分課程補助申請表",
  "審核狀態",
  //"公文號",
  //"發文日期",
  "提出日期",
  "審核通過日期",
  "培育學校",
  "年度",
  "申請(核定)金額",
  //"核定日期",
  //"核定金額",
  //"核定文號",
];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1905;
let years = new Array(9).fill().map((s, i) => year + 1 - i);

export default {
  name: "downarea",
  data() {
    return {
      headers,

      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      searchSchool: null,
      searchYear: new Date().getFullYear() - 1911 - 1,
    };
  },
  components: { EquipmentPurchases },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },

  methods: {
    isEditable(i) {
      return i.Status == 0 || i.Status == 5;
    },
    saveData() {
      this.$bvToast.toast(`儲存成功`, {
        title: "系統資訊",
        autoHideDelay: 5000,
        variant: "success",
      });
    },
    async apply(item) {
      const url = `Budgeting/SubmitSummer?seqno=${item.SeqNo}`;
      try {
        let res = await window.axios.put(url);
        if (res) {
          this.$bvToast.toast(`提交成功`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          });
        } else {
          this.$bvToast.toast(`提交失敗`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openSubsidyEdit(item) {
      store.editItem = JSON.parse(JSON.stringify(item));
      this.$router.push("/localsummerapplication");
      store.nowTab = 5;
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchName = "";
      this.searchId = "";
      this.searchGender = null;
      this.searchBirthday = {
        year: "",
        month: "",
        day: "",
      };
      this.searchSchool = null;
      this.searchEntryYear = null;
      this.searchEndYear = null;
      this.searchMajor = null;
      this.searchCategory = null;
      this.searchHometown = null;
      this.searchStatus = null;
      this.searchServiceNow = null;
      this.getData();
    },
    async getData() {
      // odata3 qs top&skip
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      // const orderBy = this.orderBy;
      const obj = { top, skip };
      let qs = buildQuery(obj);

      const account = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");

      let url = "";
      url = `Budgeting/GetSummerList?byear=${this.searchYear}&term=1&category=S`;

      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }

      if (Boolean(this.searchSchool)) {
        //this.searchSchool = this.schoolObject[0].value;
        url = `${url}&schoolcode=${this.searchSchool}`;
      }

      try {
        let { Items } = await window.axios.get(url);
        if (Items != null) {
          Items = Items.map((s) => {
            s.StatusName = s.Status == 4 ? "完成核定" : s.StatusName;
            //s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text: "";
            return s;
          });
          this.items = JSON.parse(JSON.stringify(Items));
        }
      } catch (e) {
        console.log(e);
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      //this.searchSchool = store.searchSchool?store.searchSchool: this.schoolObject[0].value;
      this.searchSchool = store.searchSchool ? store.searchSchool : null;
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    await this.getAllSchool();
    await this.getData();
  },

  watch: {
    searchSchool(v) { store.searchSchool = v; },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}

.dtc-grid-header {
  // width: 2230px;
  display: grid;
  grid-template-columns: 80px 120px 180px 130px 140px 160px 250px 180px 120px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  >div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 36px;
  }

  >div:first-child {
    border-left: 1px solid #cccccc;
  }
}

.dtc-grid-header {
  >div {
    line-height: 30px;
  }
}

.content {
  >div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  >div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

.dtc-link {
  color: var(--primary);
  cursor: pointer;
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px 220px repeat(30, max-content);
  grid-gap: 12px;

  >* {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}

.file-content {
  text-align: start;
  padding-left: 10px !important;

  .subject {
    cursor: pointer;
    font-weight: 900;
  }

  .content {
    color: #777;
  }
}

.note {
  text-align: left;
  padding-left: 5px !important;
}

.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;

  .dash {
    margin-top: 6px;
    text-align: center;
  }
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;

  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;

  .close {
    color: #ffffff;
  }
}
</style>
